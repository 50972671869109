import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import Section from '@latitude/section';
import './_labRetailerPartnerList.scss'
import { Typography } from '../Typography';
import classNames from 'classnames';

const RetailerImage = ({ src, name, link}) => {
  const isValidLink = link && link?.length > 2
  const anchorAttributes = isValidLink ? {href : link , target:'_blank', rel:'noopener noreferrer'}:{}
  const pointerEventStyle =  isValidLink ? 'auto' : 'none';
  return (
    <a {...(anchorAttributes)} style= {{pointerEvents : pointerEventStyle}}>
      <img src={src} alt={name} className='retailer-image'/>
    </a>
    );
};

const LabRetailerPartnerList = ({ data }) => {
  
  const { title, link, logos } = data[0];  
  const linkText = link?.content?.[0]?.content?.[1]?.content?.[0]?.value;
  const linkUrl = link?.content?.[0]?.content?.[1]?.data?.uri;

  return (
    <AnalyticsLocationProvider location={title}>
      <Section className="retailer-container">
        {title && <Typography.H4 className="heading">{title}</Typography.H4>}
        
        <div className="retailer-image-container">
          {logos?.map((logo, index) => {
            const logoUrl = logo?.logo?.file?.url;
            const logoName = logo?.name;
            return (
              <RetailerImage
                key={index}
                src={logoUrl}
                name={logoName}
                link=""
              />
            );
          })}
        </div>

        {linkUrl && linkText && (
          <div className='retailers-list'>
            <a href={linkUrl} className='retailers-link'>{linkText}</a>
          </div>
        )}
      </Section>
    </AnalyticsLocationProvider>
  );
};

export default LabRetailerPartnerList;