import React from 'react';

import { FeaturesSlider } from '@latitude/features-slider';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';

const CARDS_DATA = [
  {
    href: 'https://latitudefs.zendesk.com/hc/en-au',
    icon: 'icon-help-centre-faq',
    title: 'Help Centre',
    text: 'For FAQs, useful information and quick and easy forms.',
    trackId: 'help-centre'
  },
  {
    href: 'https://www.creditsmart.org.au/',
    icon: 'icon-creditsmart',
    title: 'CreditSmart',
    text: 'Find out more about credit reporting.',
    trackId: 'recommended-reads'
  },
  {
    href: '/hardship-care/',
    icon: 'bills',
    title: 'Hardship Care',
    text: "If you're struggling with your repayments, we're here to help.",
    trackId: 'hardship'
  },
  {
    href: '/digital-wallets/apple-pay/',
    icon: 'icon-pay-any-way',
    title: 'Digital wallets',
    text: 'Any mobile. Any wearable. Including Apple Pay and Samsung Pay.',
    trackId: 'digital-wallets'
  },
  {
    href: null,
    icon: 'icon-translate',
    title: 'Need an Interpreter?',
    text:
      'We can arrange one for you at no additional cost (Language Loop service is subject to availability).',
    trackId: 'need-interpreter'
  },
  {
    href: '/vulnerable-customers/',
    icon: 'icon-death',
    title: 'Vulnerable customers',
    text: 'Providing you with care and support when you need it.',
    trackId: 'vulnerable-customers'
  }
];

const RESPONSIVE_SETTINGS = [
  {
    breakpoint: 10000,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    }
  }
];
const SECTION_TITLE = "Let's Explore";

function QuickLinks(props) {
  return (
    <div
      css={`
        && {
          padding-bottom: 0;
          background-color: #f8f8f8;
        }
        && div.slick-slide div.lfs-card {
          background-color: #fff;
          border: 1px solid #ccc;
          border-bottom: 2px solid #ccc;
          padding: 10px;
        }
        && div.slick-slide div.lfs-card div.lfs-card-icon {
          width: 60px !important;
          height: 60px !important;
        }
        && div.slick-slide div.lfs-card div.text {
          height: 120px;
        }
        && div.slick-slide div.lfs-card a {
          width: fit-content;
          margin: 10px auto;
        }
      `}
    >
      <FeaturesSlider
        className="lets-explore"
        heading={SECTION_TITLE}
        id="lets-explore"
        data={CARDS_DATA.map((card, index) => {
          return {
            icon: card.icon,
            title: card.title,
            jsx: (
              <>
                <div className="text">{card.text}</div>
                {card.href && (
                  <>
                    <Link
                      button={BUTTON_STYLE.TERTIARY}
                      href={card.href}
                      trackId={`quicklinks-${card.trackId}`}
                      trackEventData={{
                        card: card.title,
                        location: SECTION_TITLE
                      }}
                    >
                      Learn more
                    </Link>
                  </>
                )}
              </>
            )
          };
        })}
        responsiveSettings={RESPONSIVE_SETTINGS}
      />
    </div>
  );
}

export default QuickLinks;
